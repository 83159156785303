<template>
    <div class="about mb-5">
        <!-- <span class="bg"></span> -->
        <!-- <LeanPage class="about-content d-none d-md-flex" :textBlocks="textBlocks" title="Privacy Policy" :policy="true" screenSize="md"/>
        <LeanPage class="about-content d-none d-sm-flex d-md-none" :textBlocks="textBlocks" title="Privacy Policy" :policy="true" screenSize="sm"/>
        <LeanPage class="about-content d-flex d-sm-none" :textBlocks="textBlocks" title="Privacy Policy" :policy="true" screenSize="xs"/> -->
        
        <!-- IF MEDIUM AND ABOVE -->
        <div class=" about-content d-none d-md-flex">
            <v-container class="white--text synThinFont" >
                <div class="text-center mb-5">
                    <v-avatar :tile="true">
                        <img :src="require('@/assets/syn_logo.svg')" alt="logo">
                    </v-avatar>
                </div>
                <h1 class="text-center letterSpacing-med synThickFont">SynthFeed</h1>
                <h2  class="text-center letterSpacing-med  synThickFont">Artist Agreement</h2>
                
                <div class="mt-5 aboutDiv" >
                    <!-- <div>{{block}}</div> -->
                        <!-- <h3 v-if="block[0] == 'small'">{{block[1]}}</h3>
                        <h2 v-else>{{block[0]}}</h2> -->
                    
                    <div class="mt-5 ">This website is operated by SynthFeed. Throughout the site, the terms “we”, “us” and “our” refer to SynthFeed offers this website, including all information, tools and services available from this site to you, the user, conditioned upon your acceptance of all terms, conditions, policies and notices stated here.</div>
                    <div class="mt-5 ">We strongly recommend that you read these Terms and Conditions carefully before you start to use our Feedback Services, as these will apply to your use of our Feedback Services and constitute a legal agreement between you and us.</div>
                    <div class="mt-5 ">By using this Service, you will be bound by our <a class="rl-no-dec  syn_blue_button--text" target="_blank" href="/terms">Terms of Use</a>, these Terms and Conditions and the SynthFeed’s <a class="rl-no-dec  syn_blue_button--text" target="_blank" href="/privacy">Privacy Policy</a>. If you do not agree to these Terms and Conditions and/or the Privacy Policy you must not use this Service or any service provided via it.  Use of the Service is expressly conditional upon your assent to all the Terms and Conditions of this agreement, to the exclusion of all other terms.</div>
                    <div class="mt-5 ">When you first use the Service, you must confirm your acceptance of these Terms and Conditions.</div>
                    <div class="mt-5 "><h2 class="synThickFont">Your Use of our Feedback service</h2></div>
                    <div class="mt-3 ">These Terms and Conditions govern your use of the Feedback Service that SynthFeed provides.</div>
                    <div class="mt-5 ">You must not use this Service for anything that is unlawful or is prohibited by these Terms and Conditions and/or any notices elsewhere on this Service.</div>
                    <div class="mt-5 ">You further agree that in relation to this Service you will not in any way conduct yourself in a manner which is unlawful or which gives rise to civil or criminal liability or which might call into disrepute the Company or the Service.</div>
                    <div class="mt-5 ">You will cooperate fully with SynthFeed to investigate any suspected unlawful, fraudulent or improper activity.</div>
                    <div class="mt-5 ">If you feel that the behaviour of another user breaches these Terms and Conditions then please let the Company know by sending an email to <a class="rl-no-dec  syn_blue_button--text" href="mailto:contact@synthfeed.com" target="_blank">contact@synthfeed.com</a> with details of the potential breach and/or the user responsible. SynthFeed will take reasonable steps to deal with any breach.</div>
                    
                    <div class="mt-5 "><h2 class="synThickFont">Your data</h2></div>
                    <div class="mt-3 ">When you use SynthFeed, we may collect personal or non-personal information from you. Please make sure you read and understand our <a class="rl-no-dec  syn_blue_button--text" target="_blank" href="/privacy">Privacy Policy</a> which describes the data we collect and what we do with it.</div>
                    
                    <div class="mt-5 "><h2 class="synThickFont">Independent Contractor Relationship</h2></div>
                    <div class="mt-3 ">This contract governs your engagement from time to time by SynthFeed. This is not an employment contract and does not confer any employment rights on you. </div>
                    <div class="mt-5 ">Each agreement entered is divisible and a separate legal agreement that, for the avoidance of any doubt, does not create any legal or other obligation to re-engage the star for any subsequent agreements. By entering into this contract, you confirm your understanding that the Company makes no promise or guarantee of a minimum level of assignments and you will work on a flexible, "as required" basis.</div>

                    <div class="mt-5 "><h2 class="synThickFont">Arrangements for Work</h2></div>
                    <div class="mt-3 ">Any offer of an assignment shall be by e-mail, following the provision of accurate e-mail or through the website and other specified contact details. Acceptance of an offer must be provided by e-mail or through the website within 72 hours of the receipt of the offer. A refusal or inability to accept an offer should, if at all possible, be conformed within 72 hours of the receipt of the offer. A constant failure to comply with this condition may prejudice future offers of assignments. The relevant offer shall specify the content conditions of the assignment and the relevant recording must comply with the relevant conditions, failing which we reserve the right to withdraw the offer and shall make no payment in respect of a non-compliant assignment.</div>
                    <div class="mt-5 ">The precise description and nature of the Feedback may be varied with each assignment. You will be informed of the requirements at the start of each assignment. A completed assignment must be conveyed to the relevant User via the upload section on the website or send per email or other means of communication as specified in the offer and within 72 hours of accepting the offer. SynthFeed may reject a Feedback if it is deemed unsuitable or of poor quality.</div>
                    
                    <div class="mt-5 "><h2 class="synThickFont">Pay</h2></div>
                    <div class="mt-3 ">You will only be paid for each delivered and accepted Feedback. SynthFeed's current rate of pay for a Feedback is 70% of the amount charged to the User under your profile section on the SynthFeed website and the remainder of 30% is retained by SynthFeed. You will be paid per Feedback via PayPal transfer within 10 working days. Please note that this is an estimate and that the actual pay out time and amount may differ in accordance with Stripe’s and PayPal’s payment processing policies and fees. SynthFeed will not be responsible for any delays in pay out times nor pension or National Insurance payments and Taxes.</div>
                    
                    <div class="mt-5 "><h2 class="synThickFont">Copyright</h2></div>
                    <div class="mt-3 ">Any feedback made for SynthFeed shall be solely used for the purpose of the assignment and the exclusive use of SynthFeed. You may not use, copy, reproduce, modify, create derivative works, publicly perform, publicly display, etc. or otherwise transmit and communicate the content, or any portion of it, in any manner or form and in any medium or forum for your own purposes or share such with any third party.</div>


                    <div class="mt-5 "><h2 class="synThickFont">Termination</h2></div>
                    <div class="mt-3 ">SynthFeed may terminate this agreement immediately by giving notice in writing to you if we reasonably consider that you have committed any serious breach of its terms or if your previous unknown or present or future conduct brings SynthFeed into disrepute or may lead to a conflict of interest. </div>
                    <div class="mt-5 ">In contrast, you may terminate this agreement and delete or deactivate your account by simply emailing us at <a class="rl-no-dec  syn_blue_button--text" href="mailto:contact@synthfeed.com" target="_blank">contact@synthfeed.com</a>.</div>


                    <div class="mt-5 "><h2 class="synThickFont">Limitation of our liability</h2></div>
                    <div class="mt-3 ">Nothing in these terms and conditions excludes or limits our liability for death or personal injury arising from our negligence, or our fraud or fraudulent misrepresentation, or any other liability that cannot be excluded or limited by law.</div>
                    <div class="mt-5 ">To the fullest extent permitted by law, aside from these Terms and Conditions and our Privacy Policy, we exclude all conditions, warranties, representations or other terms which may apply to our Sites or the Services, whether express or implied.</div>
                    <div class="mt-5 ">We will not be liable to any user for any loss or damage, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, even if foreseeable, arising under or in connection with: Use of, or inability to use, our Sites or Feedback Services; Use of, or reliance on, any content displayed on our Sites or Feedback Services. In particular, we will not be liable for: loss of profits, sales, business or revenue; business interruption; loss of anticipated savings; loss of business opportunity, goodwill or reputation; any indirect or consequential loss or damage.</div>
                    <div class="mt-5 ">Although we have no reason to believe that any information contained in the Feedback Services or the Sites is inaccurate, we do not warrant the accuracy, adequacy or completeness of the information except where warranties are made non-excludable by applicable legislation.</div>
                    
                    <div class="mt-5 "><h2 class="synThickFont">Applicable law and Jurisdiction</h2></div>
                    <div class="mt-3 ">These Terms and Conditions, their subject matter and formation, are governed by Delaware (United States) law. You and we both agree that the courts of Delaware (U.S) will have exclusive jurisdiction.</div>
                    <div class="mt-5 ">These Terms and Conditions set out the entire agreement and understanding with respect to the subject matter of these Terms and Conditions and supersedes all representations, communications and prior agreements (written or oral).</div>
                    <div class="mt-5 ">Each party acknowledges that on entering into these Terms and Conditions, it does not rely, and has not relied, upon any representation (whether negligent or innocent), statement or warranty made or agreed to by any person (whether a party to these Terms and Conditions or not) except those expressly set out in these Terms and Conditions.</div>
                    <div class="mt-5 ">Unless expressly provided in the Terms and Conditions, no term of them is enforceable by any person who is not a party to it.</div>
                    
                    <!-- <div class="mt-5 "><h2 class="synThickFont"></h2></div>
                    <div class="mt-3 "></div>
                    <div class="mt-5 "></div>
                    <div class="mt-5 "></div>
                    <div class="mt-5 "> 
                        <ul class="mt-5 ">
                            <li></li>
                        </ul>
                    </div>
                    <div class="mt-5 "><h2 class="synThickFont"></h2></div>
                    <div class="mt-3 "></div>
                    <div class="mt-5 "><h3 class="synThickFont"></h3></div>
                    <div class="mt-1 "></div> -->
                </div>   
            </v-container>
        </div>

         <!-- IF SMALL -->
        <div class=" about-content d-none d-sm-flex d-md-none">
            <v-container class="white--text synThinFont" >
                <div class="text-center mb-5">
                    <v-avatar :tile="true">
                        <img :src="require('@/assets/syn_logo.svg')" alt="logo">
                    </v-avatar>
                </div>
                <h1 class="text-center letterSpacing-med synThickFont">SynthFeed</h1>
                <h2  class="text-center letterSpacing-med  synThickFont">Artist Agreement</h2>
                
                <div class="mt-5 aboutDivSM" >
                    <!-- <div>{{block}}</div> -->
                        <!-- <h3 v-if="block[0] == 'small'">{{block[1]}}</h3>
                        <h2 v-else>{{block[0]}}</h2> -->
                    
                    <div class="mt-5 ">This website is operated by SynthFeed. Throughout the site, the terms “we”, “us” and “our” refer to SynthFeed offers this website, including all information, tools and services available from this site to you, the user, conditioned upon your acceptance of all terms, conditions, policies and notices stated here.</div>
                    <div class="mt-5 ">We strongly recommend that you read these Terms and Conditions carefully before you start to use our Feedback Services, as these will apply to your use of our Feedback Services and constitute a legal agreement between you and us.</div>
                    <div class="mt-5 ">By using this Service, you will be bound by our <a class="rl-no-dec  syn_blue_button--text" target="_blank" href="/terms">Terms of Use</a>, these Terms and Conditions and the SynthFeed’s <a class="rl-no-dec  syn_blue_button--text" target="_blank" href="/privacy">Privacy Policy</a>. If you do not agree to these Terms and Conditions and/or the Privacy Policy you must not use this Service or any service provided via it.  Use of the Service is expressly conditional upon your assent to all the Terms and Conditions of this agreement, to the exclusion of all other terms.</div>
                    <div class="mt-5 ">When you first use the Service, you must confirm your acceptance of these Terms and Conditions.</div>
                    <div class="mt-5 "><h2 class="synThickFont">Your Use of our Feedback service</h2></div>
                    <div class="mt-3 ">These Terms and Conditions govern your use of the Feedback Service that SynthFeed provides.</div>
                    <div class="mt-5 ">You must not use this Service for anything that is unlawful or is prohibited by these Terms and Conditions and/or any notices elsewhere on this Service.</div>
                    <div class="mt-5 ">You further agree that in relation to this Service you will not in any way conduct yourself in a manner which is unlawful or which gives rise to civil or criminal liability or which might call into disrepute the Company or the Service.</div>
                    <div class="mt-5 ">You will cooperate fully with SynthFeed to investigate any suspected unlawful, fraudulent or improper activity.</div>
                    <div class="mt-5 ">If you feel that the behaviour of another user breaches these Terms and Conditions then please let the Company know by sending an email to <a class="rl-no-dec  syn_blue_button--text" href="mailto:contact@synthfeed.com" target="_blank">contact@synthfeed.com</a> with details of the potential breach and/or the user responsible. SynthFeed will take reasonable steps to deal with any breach.</div>
                    
                    <div class="mt-5 "><h2 class="synThickFont">Your data</h2></div>
                    <div class="mt-3 ">When you use SynthFeed, we may collect personal or non-personal information from you. Please make sure you read and understand our <a class="rl-no-dec  syn_blue_button--text" target="_blank" href="/privacy">Privacy Policy</a> which describes the data we collect and what we do with it.</div>
                    
                    <div class="mt-5 "><h2 class="synThickFont">Independent Contractor Relationship</h2></div>
                    <div class="mt-3 ">This contract governs your engagement from time to time by SynthFeed. This is not an employment contract and does not confer any employment rights on you. </div>
                    <div class="mt-5 ">Each agreement entered is divisible and a separate legal agreement that, for the avoidance of any doubt, does not create any legal or other obligation to re-engage the star for any subsequent agreements. By entering into this contract, you confirm your understanding that the Company makes no promise or guarantee of a minimum level of assignments and you will work on a flexible, "as required" basis.</div>

                    <div class="mt-5 "><h2 class="synThickFont">Arrangements for Work</h2></div>
                    <div class="mt-3 ">Any offer of an assignment shall be by e-mail, following the provision of accurate e-mail or through the website and other specified contact details. Acceptance of an offer must be provided by e-mail or through the website within 72 hours of the receipt of the offer. A refusal or inability to accept an offer should, if at all possible, be conformed within 72 hours of the receipt of the offer. A constant failure to comply with this condition may prejudice future offers of assignments. The relevant offer shall specify the content conditions of the assignment and the relevant recording must comply with the relevant conditions, failing which we reserve the right to withdraw the offer and shall make no payment in respect of a non-compliant assignment.</div>
                    <div class="mt-5 ">The precise description and nature of the Feedback may be varied with each assignment. You will be informed of the requirements at the start of each assignment. A completed assignment must be conveyed to the relevant User via the upload section on the website or send per email or other means of communication as specified in the offer and within 72 hours of accepting the offer. SynthFeed may reject a Feedback if it is deemed unsuitable or of poor quality.</div>
                    
                    <div class="mt-5 "><h2 class="synThickFont">Pay</h2></div>
                    <div class="mt-3 ">You will only be paid for each delivered and accepted Feedback. SynthFeed's current rate of pay for a Feedback is 70% of the amount charged to the User under your profile section on the SynthFeed website and the remainder of 30% is retained by SynthFeed. You will be paid per Feedback via PayPal transfer within 10 working days. Please note that this is an estimate and that the actual pay out time and amount may differ in accordance with Stripe’s and PayPal’s payment processing policies and fees. SynthFeed will not be responsible for any delays in pay out times nor pension or National Insurance payments and Taxes.</div>
                    
                    <div class="mt-5 "><h2 class="synThickFont">Copyright</h2></div>
                    <div class="mt-3 ">Any feedback made for SynthFeed shall be solely used for the purpose of the assignment and the exclusive use of SynthFeed. You may not use, copy, reproduce, modify, create derivative works, publicly perform, publicly display, etc. or otherwise transmit and communicate the content, or any portion of it, in any manner or form and in any medium or forum for your own purposes or share such with any third party.</div>


                    <div class="mt-5 "><h2 class="synThickFont">Termination</h2></div>
                    <div class="mt-3 ">SynthFeed may terminate this agreement immediately by giving notice in writing to you if we reasonably consider that you have committed any serious breach of its terms or if your previous unknown or present or future conduct brings SynthFeed into disrepute or may lead to a conflict of interest. </div>
                    <div class="mt-5 ">In contrast, you may terminate this agreement and delete or deactivate your account by simply emailing us at <a class="rl-no-dec  syn_blue_button--text" href="mailto:contact@synthfeed.com" target="_blank">contact@synthfeed.com</a>.</div>


                    <div class="mt-5 "><h2 class="synThickFont">Limitation of our liability</h2></div>
                    <div class="mt-3 ">Nothing in these terms and conditions excludes or limits our liability for death or personal injury arising from our negligence, or our fraud or fraudulent misrepresentation, or any other liability that cannot be excluded or limited by law.</div>
                    <div class="mt-5 ">To the fullest extent permitted by law, aside from these Terms and Conditions and our Privacy Policy, we exclude all conditions, warranties, representations or other terms which may apply to our Sites or the Services, whether express or implied.</div>
                    <div class="mt-5 ">We will not be liable to any user for any loss or damage, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, even if foreseeable, arising under or in connection with: Use of, or inability to use, our Sites or Feedback Services; Use of, or reliance on, any content displayed on our Sites or Feedback Services. In particular, we will not be liable for: loss of profits, sales, business or revenue; business interruption; loss of anticipated savings; loss of business opportunity, goodwill or reputation; any indirect or consequential loss or damage.</div>
                    <div class="mt-5 ">Although we have no reason to believe that any information contained in the Feedback Services or the Sites is inaccurate, we do not warrant the accuracy, adequacy or completeness of the information except where warranties are made non-excludable by applicable legislation.</div>
                    
                    <div class="mt-5 "><h2 class="synThickFont">Applicable law and Jurisdiction</h2></div>
                    <div class="mt-3 ">These Terms and Conditions, their subject matter and formation, are governed by Delaware (United States) law. You and we both agree that the courts of Delaware (U.S) will have exclusive jurisdiction.</div>
                    <div class="mt-5 ">These Terms and Conditions set out the entire agreement and understanding with respect to the subject matter of these Terms and Conditions and supersedes all representations, communications and prior agreements (written or oral).</div>
                    <div class="mt-5 ">Each party acknowledges that on entering into these Terms and Conditions, it does not rely, and has not relied, upon any representation (whether negligent or innocent), statement or warranty made or agreed to by any person (whether a party to these Terms and Conditions or not) except those expressly set out in these Terms and Conditions.</div>
                    <div class="mt-5 ">Unless expressly provided in the Terms and Conditions, no term of them is enforceable by any person who is not a party to it.</div>
                    
                    <!-- <div class="mt-5 "><h2 class="synThickFont"></h2></div>
                    <div class="mt-3 "></div>
                    <div class="mt-5 "></div>
                    <div class="mt-5 "></div>
                    <div class="mt-5 "> 
                        <ul class="mt-5 ">
                            <li></li>
                        </ul>
                    </div>
                    <div class="mt-5 "><h2 class="synThickFont"></h2></div>
                    <div class="mt-3 "></div>
                    <div class="mt-5 "><h3 class="synThickFont"></h3></div>
                    <div class="mt-1 "></div> -->
                </div>   
            </v-container>
        </div>

         <!-- IF XS -->
        <div class=" about-content d-flex d-sm-none">
            <v-container class="white--text synThinFont" >
                <div class="text-center mb-5">
                    <v-avatar :tile="true">
                        <img :src="require('@/assets/syn_logo.svg')" alt="logo">
                    </v-avatar>
                </div>
                <h1 class="text-center letterSpacing-med synThickFont">SynthFeed</h1>
                <h2  class="text-center letterSpacing-med  synThickFont">Artist Agreement</h2>
                
                <div class="mt-5 aboutDivXS" >
                    <!-- <div>{{block}}</div> -->
                        <!-- <h3 v-if="block[0] == 'small'">{{block[1]}}</h3>
                        <h2 v-else>{{block[0]}}</h2> -->
                    
                    <div class="mt-5 ">This website is operated by SynthFeed. Throughout the site, the terms “we”, “us” and “our” refer to SynthFeed offers this website, including all information, tools and services available from this site to you, the user, conditioned upon your acceptance of all terms, conditions, policies and notices stated here.</div>
                    <div class="mt-5 ">We strongly recommend that you read these Terms and Conditions carefully before you start to use our Feedback Services, as these will apply to your use of our Feedback Services and constitute a legal agreement between you and us.</div>
                    <div class="mt-5 ">By using this Service, you will be bound by our <a class="rl-no-dec  syn_blue_button--text" target="_blank" href="/terms">Terms of Use</a>, these Terms and Conditions and the SynthFeed’s <a class="rl-no-dec  syn_blue_button--text" target="_blank" href="/privacy">Privacy Policy</a>. If you do not agree to these Terms and Conditions and/or the Privacy Policy you must not use this Service or any service provided via it.  Use of the Service is expressly conditional upon your assent to all the Terms and Conditions of this agreement, to the exclusion of all other terms.</div>
                    <div class="mt-5 ">When you first use the Service, you must confirm your acceptance of these Terms and Conditions.</div>
                    <div class="mt-5 "><h2 class="synThickFont">Your Use of our Feedback service</h2></div>
                    <div class="mt-3 ">These Terms and Conditions govern your use of the Feedback Service that SynthFeed provides.</div>
                    <div class="mt-5 ">You must not use this Service for anything that is unlawful or is prohibited by these Terms and Conditions and/or any notices elsewhere on this Service.</div>
                    <div class="mt-5 ">You further agree that in relation to this Service you will not in any way conduct yourself in a manner which is unlawful or which gives rise to civil or criminal liability or which might call into disrepute the Company or the Service.</div>
                    <div class="mt-5 ">You will cooperate fully with SynthFeed to investigate any suspected unlawful, fraudulent or improper activity.</div>
                    <div class="mt-5 ">If you feel that the behaviour of another user breaches these Terms and Conditions then please let the Company know by sending an email to <a class="rl-no-dec  syn_blue_button--text" href="mailto:contact@synthfeed.com" target="_blank">contact@synthfeed.com</a> with details of the potential breach and/or the user responsible. SynthFeed will take reasonable steps to deal with any breach.</div>
                    
                    <div class="mt-5 "><h2 class="synThickFont">Your data</h2></div>
                    <div class="mt-3 ">When you use SynthFeed, we may collect personal or non-personal information from you. Please make sure you read and understand our <a class="rl-no-dec  syn_blue_button--text" target="_blank" href="/privacy">Privacy Policy</a> which describes the data we collect and what we do with it.</div>
                    
                    <div class="mt-5 "><h2 class="synThickFont">Independent Contractor Relationship</h2></div>
                    <div class="mt-3 ">This contract governs your engagement from time to time by SynthFeed. This is not an employment contract and does not confer any employment rights on you. </div>
                    <div class="mt-5 ">Each agreement entered is divisible and a separate legal agreement that, for the avoidance of any doubt, does not create any legal or other obligation to re-engage the star for any subsequent agreements. By entering into this contract, you confirm your understanding that the Company makes no promise or guarantee of a minimum level of assignments and you will work on a flexible, "as required" basis.</div>

                    <div class="mt-5 "><h2 class="synThickFont">Arrangements for Work</h2></div>
                    <div class="mt-3 ">Any offer of an assignment shall be by e-mail, following the provision of accurate e-mail or through the website and other specified contact details. Acceptance of an offer must be provided by e-mail or through the website within 72 hours of the receipt of the offer. A refusal or inability to accept an offer should, if at all possible, be conformed within 72 hours of the receipt of the offer. A constant failure to comply with this condition may prejudice future offers of assignments. The relevant offer shall specify the content conditions of the assignment and the relevant recording must comply with the relevant conditions, failing which we reserve the right to withdraw the offer and shall make no payment in respect of a non-compliant assignment.</div>
                    <div class="mt-5 ">The precise description and nature of the Feedback may be varied with each assignment. You will be informed of the requirements at the start of each assignment. A completed assignment must be conveyed to the relevant User via the upload section on the website or send per email or other means of communication as specified in the offer and within 72 hours of accepting the offer. SynthFeed may reject a Feedback if it is deemed unsuitable or of poor quality.</div>
                    
                    <div class="mt-5 "><h2 class="synThickFont">Pay</h2></div>
                    <div class="mt-3 ">You will only be paid for each delivered and accepted Feedback. SynthFeed's current rate of pay for a Feedback is 70% of the amount charged to the User under your profile section on the SynthFeed website and the remainder of 30% is retained by SynthFeed. You will be paid per Feedback via PayPal transfer within 10 working days. Please note that this is an estimate and that the actual pay out time and amount may differ in accordance with Stripe’s and PayPal’s payment processing policies and fees. SynthFeed will not be responsible for any delays in pay out times nor pension or National Insurance payments and Taxes.</div>
                    
                    <div class="mt-5 "><h2 class="synThickFont">Copyright</h2></div>
                    <div class="mt-3 ">Any feedback made for SynthFeed shall be solely used for the purpose of the assignment and the exclusive use of SynthFeed. You may not use, copy, reproduce, modify, create derivative works, publicly perform, publicly display, etc. or otherwise transmit and communicate the content, or any portion of it, in any manner or form and in any medium or forum for your own purposes or share such with any third party.</div>


                    <div class="mt-5 "><h2 class="synThickFont">Termination</h2></div>
                    <div class="mt-3 ">SynthFeed may terminate this agreement immediately by giving notice in writing to you if we reasonably consider that you have committed any serious breach of its terms or if your previous unknown or present or future conduct brings SynthFeed into disrepute or may lead to a conflict of interest. </div>
                    <div class="mt-5 ">In contrast, you may terminate this agreement and delete or deactivate your account by simply emailing us at <a class="rl-no-dec  syn_blue_button--text" href="mailto:contact@synthfeed.com" target="_blank">contact@synthfeed.com</a>.</div>


                    <div class="mt-5 "><h2 class="synThickFont">Limitation of our liability</h2></div>
                    <div class="mt-3 ">Nothing in these terms and conditions excludes or limits our liability for death or personal injury arising from our negligence, or our fraud or fraudulent misrepresentation, or any other liability that cannot be excluded or limited by law.</div>
                    <div class="mt-5 ">To the fullest extent permitted by law, aside from these Terms and Conditions and our Privacy Policy, we exclude all conditions, warranties, representations or other terms which may apply to our Sites or the Services, whether express or implied.</div>
                    <div class="mt-5 ">We will not be liable to any user for any loss or damage, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, even if foreseeable, arising under or in connection with: Use of, or inability to use, our Sites or Feedback Services; Use of, or reliance on, any content displayed on our Sites or Feedback Services. In particular, we will not be liable for: loss of profits, sales, business or revenue; business interruption; loss of anticipated savings; loss of business opportunity, goodwill or reputation; any indirect or consequential loss or damage.</div>
                    <div class="mt-5 ">Although we have no reason to believe that any information contained in the Feedback Services or the Sites is inaccurate, we do not warrant the accuracy, adequacy or completeness of the information except where warranties are made non-excludable by applicable legislation.</div>
                    
                    <div class="mt-5 "><h2 class="synThickFont">Applicable law and Jurisdiction</h2></div>
                    <div class="mt-3 ">These Terms and Conditions, their subject matter and formation, are governed by Delaware (United States) law. You and we both agree that the courts of Delaware (U.S) will have exclusive jurisdiction.</div>
                    <div class="mt-5 ">These Terms and Conditions set out the entire agreement and understanding with respect to the subject matter of these Terms and Conditions and supersedes all representations, communications and prior agreements (written or oral).</div>
                    <div class="mt-5 ">Each party acknowledges that on entering into these Terms and Conditions, it does not rely, and has not relied, upon any representation (whether negligent or innocent), statement or warranty made or agreed to by any person (whether a party to these Terms and Conditions or not) except those expressly set out in these Terms and Conditions.</div>
                    <div class="mt-5 ">Unless expressly provided in the Terms and Conditions, no term of them is enforceable by any person who is not a party to it.</div>
                    
                    <!-- <div class="mt-5 "><h2 class="synThickFont"></h2></div>
                    <div class="mt-3 "></div>
                    <div class="mt-5 "></div>
                    <div class="mt-5 "></div>
                    <div class="mt-5 "> 
                        <ul class="mt-5 ">
                            <li></li>
                        </ul>
                    </div>
                    <div class="mt-5 "><h2 class="synThickFont"></h2></div>
                    <div class="mt-3 "></div>
                    <div class="mt-5 "><h3 class="synThickFont"></h3></div>
                    <div class="mt-1 "></div> -->
                </div>   
            </v-container>
        </div>


    </div>
</template>
<script>

export default {
    name:"ArtistAgreement",
    data() {
        return {
        }
    },
}
</script>
<style>
.aboutDiv {
    width:80%;
    max-width: 800px;
    text-align: justify;
    line-height: 2em;
    margin: auto;
}
.aboutDivTitleXS{
    font-size:1.3em; 
    letter-spacing: 10px;
}

.aboutDivSM {
    width:89%;
    text-align: justify;
    line-height: 2em;
    margin: auto;
}

.aboutDivXS {
    width:100%;
    text-align: justify;
    line-height: 1.5em;
    margin: auto;
}
</style>
